import { Paragraph } from 'ui/components/1-atoms/Paragraph/Paragraph';
import styles from './PreviewCard.module.scss';
import { RichText } from 'ui/components/1-atoms/RichText/RichText';
import { Picture, PictureProps } from 'ui/components/1-atoms/Media/Picture/Picture';
import { Link } from 'ui/components/1-atoms/Action/Link/Link';
import { Heading } from 'ui/components/1-atoms/Heading/Heading';
import classNames from 'classnames';
import { LinkType } from 'helpers/types';

export type PreviewCardImageSizeOptions = 'small' | 'medium' | 'large';

export interface PreviewCardProps {
	featuredImage?: PictureProps;
	image?: PictureProps;
	imageSize?: 'small' | 'medium' | 'large';
	imageCropping?: 'cover' | 'contain';
	tags?: {
		// todo maybe refactor to array
		first?: string;
		second?: string;
	};
	headline?: string;
	text?: string;
	link?: LinkType;
}

export const PreviewCard: React.FC<PreviewCardProps> = ({ featuredImage, image, imageSize, imageCropping = 'cover', tags, headline, text, link }) => {
	return (
		<div className={styles.PreviewCard}>
			{link && (
				<Link href={link.url} noUnderline={true}>
					{image && image.url && (
						<div className={classNames(styles.PreviewCard_image, styles[`PreviewCard_image___${imageSize}`])}>
							{featuredImage && featuredImage.url ? (
								<Picture
									url={featuredImage.url}
									altText={featuredImage.altText}
									imageCropping={imageCropping}
									focalPoint={featuredImage.focalPoint}
									sizes="(max-width: 768px) 100vw, 575px"
								/>
							) : (
								<Picture
									url={image.url}
									altText={image.altText}
									imageCropping={imageCropping}
									focalPoint={image.focalPoint}
									sizes="(max-width: 768px) 100vw,  575px"
								/>
							)}
						</div>
					)}
					<div className={styles.PreviewCard_info}>
						{tags && (
							<div className={styles.PreviewCard_tags}>
								{tags.first && <Paragraph>{tags.first}</Paragraph>}
								{tags.second && <Paragraph className={styles.PreviewCard_secondTag}>{tags.second}</Paragraph>}
							</div>
						)}
						{headline && (
							<Heading headingLevel="h2" className={styles.PreviewCard_headline}>
								{headline}
							</Heading>
						)}
						{text && <RichText className={styles.PreviewCard_richText} content={text} />}
					</div>
				</Link>
			)}
		</div>
	);
};
