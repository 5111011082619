import type { PreviewCardImageSizeOptions, PreviewCardProps } from 'ui/components/2-molecules/Card/PreviewCard/PreviewCard';
import styles from './PreviewCardList.module.scss';
import { PreviewCard } from 'ui/components/2-molecules/Card/PreviewCard/PreviewCard';
import { Button } from 'ui/components/1-atoms/Action/Button/Button';
import { TopLevelGrid } from 'ui/components/4-habitats/TopLevelGrid/TopLevelGrid';
import { TopLevelGridCell, TopLevelGridColOptions } from 'ui/components/4-habitats/TopLevelGrid/TopLevelGridCell';
import { useState } from 'react';
import classNames from 'classnames';
import { GridCellProps } from 'ui/components/4-habitats/Grid/GridCell';

export interface PreviewCardListProps {
	items: PreviewCardProps[];
	buttonText?: string;
	desktopWidth?: GridCellProps['desktopWidth'];
	mobileWidth?: GridCellProps['mobileWidth'];
}

const indexToWidthAndOffsetMap: Record<
	number,
	{ width: TopLevelGridColOptions; offset: TopLevelGridColOptions | null; imgSize: PreviewCardImageSizeOptions }
> = {
	0: {
		width: '10col',
		offset: null,
		imgSize: 'medium',
	},
	1: {
		width: '6col',
		offset: '1col',
		imgSize: 'large',
	},
	2: {
		width: '6col',
		offset: '1col',
		imgSize: 'small',
	},
	3: {
		width: '6col',
		offset: null,
		imgSize: 'large',
	},
	4: {
		width: '10col',
		offset: '1col',
		imgSize: 'medium',
	},
	5: {
		width: '6col',
		offset: '1col',
		imgSize: 'small',
	},
	6: {
		width: '10col',
		offset: null,
		imgSize: 'medium',
	},
	7: {
		width: '6col',
		offset: '1col',
		imgSize: 'small',
	},
	8: {
		width: '6col',
		offset: '1col',
		imgSize: 'large',
	},
	9: {
		width: '6col',
		offset: null,
		imgSize: 'large',
	},
	10: {
		width: '6col',
		offset: '1col',
		imgSize: 'small',
	},
	11: {
		width: '10col',
		offset: '1col',
		imgSize: 'medium',
	},
};

export const PreviewCardList: React.FC<PreviewCardListProps> = ({ items, buttonText }) => {
	const defaultElementsNumber = 12;
	const [shownNumber, setShownNumber] = useState<number>(items.length > defaultElementsNumber ? defaultElementsNumber : items.length);

	return (
		<div className={classNames(styles.PreviewCardList)}>
			<TopLevelGrid wrap scaleDown nogutter>
				{items.slice(0, shownNumber).map((item, index) => {
					const { width, offset, imgSize } = indexToWidthAndOffsetMap[index % 12];
					return (
						<TopLevelGridCell key={index} desktopWidth={width} desktopOffset={offset} mobileWidth="100">
							<PreviewCard {...item} imageSize={imgSize} />
						</TopLevelGridCell>
					);
				})}

				{shownNumber < items.length && buttonText && (
					<TopLevelGridCell desktopWidth="24col">
						<Button
							inheritsColor
							onClick={() => {
								if (shownNumber <= items.length - defaultElementsNumber) {
									setShownNumber(shownNumber + defaultElementsNumber);
								} else {
									const difference = items.length - shownNumber;
									setShownNumber(shownNumber + difference);
								}
							}}
							style="primary"
							title={buttonText}
							type="button"
							className={styles.PreviewCardList_btn}
						>
							{buttonText}
						</Button>
					</TopLevelGridCell>
				)}
			</TopLevelGrid>
		</div>
	);
};
